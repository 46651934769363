import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import LolArenaStatsPatch from "@/game-lol/models/lol-arena-stats-patch.mjs";
import LoLChampionsArenaStats from "@/game-lol/models/lol-champions-arena.mjs";
import LoLChampionsArenaDuosStats from "@/game-lol/models/lol-champions-arena-duos.mjs";
import {
  updateLatestChampionsPatch,
  updateLatestSynergiesPatch,
} from "@/game-lol/utils/actions.mjs";
import { getKeyForArenaChampionsData } from "@/game-lol/utils/arena-queue-utils.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
} from "@/game-lol/utils/util.mjs";

const META_STATS = {
  overview: true,
  undefined: true,
  aram: true,
  arena: true,
  "arena-duos": true,
  "nexus-blitz": true,
  "one-for-all": true,
  urf: true,
};

// path regExp: /lol/champions/:overview|:aram|:arena|:nexus-blitz|:urf|:one-for-all|:synergies
async function fetchData(params, searchParams) {
  const [tab] = params;

  if (META_STATS[tab]) {
    if (tab === "arena") {
      const { patch } = await getData(
        API.STATIC.getArenaStatsPatch(),
        LolArenaStatsPatch,
        ["lol", "arenaStatsPatch"],
      );
      const key = getKeyForArenaChampionsData(patch);
      await getData(
        API.AGGREGATE.getArenaChampionsStats(patch),
        LoLChampionsArenaStats,
        ["lol", "championsStats", key],
      );
      updateLatestChampionsPatch([{ patch }]);
    } else if (tab === "arena-duos") {
      const { patch } = await getData(
        API.STATIC.getArenaStatsPatch(),
        LolArenaStatsPatch,
        ["lol", "arenaStatsPatch"],
      );
      const key = getKeyForArenaChampionsData(patch, true);
      // const patchFull = getLatestPatchForStaticData()
      // const patch = prettyPatch(patchFull);
      await getData(
        API.AGGREGATE.getArenaChampionsDuosStats(patch),
        LoLChampionsArenaDuosStats,
        ["lol", "championsStats", key],
      );
    } else {
      const filters = getDefaultedFiltersForChampions(searchParams, tab);
      const urlParams = getSearchParamsForChampions(false, filters);

      await API.AGGREGATE.getChampionsStats({
        filters,
        urlParams,
      }).then((data) => {
        updateLatestChampionsPatch(data);
      });
    }
  } else if (tab === "synergies") {
    const filters = getDefaultedFiltersForChampions(searchParams, tab);
    const urlParams = getSearchParamsForChampions(true, filters);

    await API.AGGREGATE.getSynergies({
      filters,
      urlParams,
    }).then((data) => {
      updateLatestSynergiesPatch(data);
    });
  }
}

export default fetchData;
